<template>
  <div>

    <b-card>
      <strong>
        <a
          ref="noopener noreferrer"
          href="https://www.dell.com/support/kbdoc/en-us/000205512/target-revisions-and-runtime"
          target="_blank"
        >
          Minimum, Recommended, and Latest Code Versions for Dell Technologies Servers, Storage, and Networking products
        </a>
      </strong>

    </b-card>

    <b-card
      header-bg-variant="primary"
      header-text-variant="white"
      border-variant="danger"
    >
      <b-card-title class="bg-danger text-light p-1">
        <div>
          <feather-icon
            icon="AlertCircleIcon"
            variant="danger"
            style="vertical-align: baseline;"
          /> [03.04.25] ACTION NEEDED: DataDomain Update Available and Recommended
        </div>
      </b-card-title>
      <p>There may be a security concern with the current versions of DDOS on Dell DataDomain systems.</p>
      <p>Details can be found in this DTA from Dell:
        <br>
        <strong>
          <a
            ref="noopener noreferrer"
            href="https://www.dell.com/support/kbdoc/de-de/000300899/dsa-2025-139-dell-technologies-powerprotect-data-domain-security-update-for-a-security-vulnerability"
            target="_blank"
          >
            DSA-2025-139: Security Update for Dell PowerProtect DD Vulnerability
          </a>
        </strong>
      </p>
      <p>Further details can be found in the Dell document.
        The DDOS software can be downloaded from Dell Support (login required):
        <strong>
          <a
            ref="noopener noreferrer"
            target="_blank"
            href="https://www.dell.com/support/home/de-de/product-support/product/data-domain/drivers"
          >Support for Data Domain | Drivers and Downloads | Dell Germany
          </a>
        </strong>
      </p>
      <p>If you have any further questions, please contact our support team.</p>
    </b-card>

    <b-card title="Exciting Product Update from ONEInsight Agent!">

      <div class="mb-2">
        <h3>Agent - Expanded System Support</h3>
        <p>Big news! The ONEInsight Agent has broadened its horizons. We now offer full support for "PowerVault ME4", "Brocade", and "Dell Server". Dive into a world of enhanced compatibility!</p>

        <b-button
          href="/downloads"
          variant="primary"
          size="sm"
        >
          Download new Agent
        </b-button>
      </div>

      <div>
        <h3>Support Portal - Generic Autosupport:</h3>
        <p>Discover the latest upgrade to our Support Portal. We've introduced a streamlined way to view autosupport data for "Brocade", "NetApp", "Dell Server", and "Cyber Recovery". Navigate to the "Autosupport - Generic" tab for all your data needs, presented simply and efficiently. You may need sufficient permissions from your organization administrator to view generic Autosupport data.</p>
      </div>

      <p>Embrace these new updates designed to optimize your experience and enhance productivity. Explore what's new with the ONEInsight Agent and Support Portal today!</p>

    </b-card>

    <b-card title="Two-Factor Authentication for Enhanced Security">

      <b-img
        src="/assets/mentiq-2fa.png"
        fluid
        width="560"
      />

      <p>We are thrilled to introduce the latest version of our mentIQ Service Portal, which brings a significant security upgrade. Starting now, you can enable Two-Factor Authentication (2FA) with authentication apps to further bolster the security of your organization and your data.</p>
      <h3>Why is Two-Factor Authentication crucial?</h3>
      <p>Two-Factor Authentication (2FA) is a proven method to safeguard your account against unauthorized access. It provides an additional layer of security by requiring not only your password but also a one-time confirmation through your authentication app. This means that even if someone knows your password, they cannot access your account without the second layer of authentication.</p>
      <h3>Custom Settings for Organization Administrators</h3>
      <p>
        With our new version of the mentIQ Service Portal, organization administrators have complete control over Two-Factor Authentication. They can easily configure and customize this security feature for each user, tailoring it to meet the requirements of your organization.
        <br>Your security is of utmost importance to us, and with Two-Factor Authentication, we are taking another step to ensure that your data and account are maximally protected.
      </p>
      <p>Thank you for using mentIQ to make your organization more efficient and secure!</p>

      <b-button
        href="/assets/2fa-tutorial.pdf"
        variant="primary"
        target="_blank"
      >
        Tutorial how to use 2FA
      </b-button>

    </b-card>

    <b-card title="mentIQ Service Portal 2.0 – NEW">

      <video
        width="560"
        height="315"
        controls
        controlslist="nodownload"
        poster="/assets/video_poster.png"
      >
        <source
          src="/assets/mentIQ Portal _ Deine Daten in nur einem Tool.mp4"
          type="video/mp4"
        >
        Your browser does not support the video. You can try to watch the video on <a
          href="https://www.youtube-nocookie.com/embed/mKZYkbT1P6k"
          target="_blank"
        >YouTube</a>.
      </video>

      <h3 class="mt-2">
        Organizational:
      </h3>
      <ul>
        <li>Organize your Assets in Units – visualization of company structure</li>
        <li>RBAC – Role based access for users</li>
        <li>Assign users to organization units to manage insights to assets and autosupport data</li>
      </ul>

      <h3>Monitoring:</h3>
      <ul>
        <li>Intuitive Charting and visualization</li>
        <li>Programmable monitors</li>
        <li>Standardization of autosupport data of different systems</li>
      </ul>
      <h3>Development:</h3>
      <ul>
        <li>Agile development with Azure DevOps</li>
        <li>Container driven for more scalability</li>
        <li>Kubernetes Ready</li>
      </ul>

    </b-card>

  </div>
</template>

<script>
import {
  BCard, BImg, BButton, BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BButton,
    BCardTitle,
  },
}
</script>

<style>

</style>
